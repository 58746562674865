import React, { useRef } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ESW_CLASSNAMES from '../esw/classnames'
import RemoveFromCart from './remove-from-cart'
import PriceDisplay from './price-display'
import useGetAllProduct from '../hooks/useGetAllProduct'

import cancelicon from '../images/svgs/cancel-icon-black.svg'

export default function CartItem({ item }) {
    const allProduct = useGetAllProduct()
    const rowRef = useRef(null)

    const { product_id, qty } = item
    const { node } = allProduct.find(({ node }) => node.productId === product_id)
    const { product_name, shop_categories, slug, childrenFile } = node
    
    return (
        <div ref={rowRef} className={`${ESW_CLASSNAMES.CART_ITEM} border-bottom border-dark pb-3 mb-3`}>
            <Row className='align-items-center'>
                {childrenFile[0] && <Col xs='auto'>
                    <GatsbyImage
                        image={getImage(childrenFile[0])}
                        alt=''
                        style={{ width: 100 }}
                        objectFit='contain'
                    />
                </Col>}
                <Col className='d-none d-md-flex flex-column'>
                    <p className='ff-primary text-uppercase fw-bold mb-0' style={{ fontSize: 24 }}>
                        {product_name}
                    </p>
                    <p className='text-secondary text-uppercase fw-semibold mb-0'>
                        {shop_categories[0].name}
                    </p>
                </Col>
                <Col>
                    <div className='d-flex d-md-none flex-column mb-3'>
                        <p className='ff-primary text-uppercase fw-bold mb-0' style={{ fontSize: 20 }}>
                            {product_name}
                        </p>
                        <p className='text-secondary text-uppercase fw-semibold mb-0'  style={{ fontSize: 12 }}>
                            {shop_categories[0].name}
                        </p>
                    </div>
                    <PriceDisplay
                        item={item}
                        product={node}
                        quantity={qty}
                        style={{ fontSize: 18 }}
                        className='d-flex align-items-center gap-2 mb-0 fw-semibold'
                        eswPriceClassName={ESW_CLASSNAMES.CART_PRICE}
                        eswPriceStrikedClassname={ESW_CLASSNAMES.PAGE_PRICE}
                    />
                </Col>
                <Col xs='auto'>
                    <RemoveFromCart el={rowRef} product={node} className='flex-center'>
                        <img src={cancelicon} alt='' />
                    </RemoveFromCart>
                </Col>
            </Row>
        </div>
    )
}