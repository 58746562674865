import React, { useContext, useRef, useEffect } from 'react'
import AppContext from '../context/app'
import ESW_CLASSNAMES from '../esw/classnames'

export default function Subtotal() {
    const { cart, country, prices } = useContext(AppContext)

    function reducer(total, item) {
        const { product_id, qty, } = item
        const price = prices[product_id][country.node.country_iso_code]
        total += price?.price * qty
        return total
    }

    const elRef = useRef(null)
    useEffect(() => {
        if(!elRef || !elRef.current || !country || !cart || !prices) return

        const value = cart?.items.reduce(reducer, 0).toFixed(2)
        const display = `${country?.node.currency_symbol}${value}`

        elRef.current.innerText = display
    }, [elRef, country, cart, prices])

    return <s ref={elRef}
        className={`${ESW_CLASSNAMES.CART_SUBTOTAL_BEFORE_DISCOUNT} fw-semibold text-secondary`}
        style={{ fontSize: 18 }}
    ></s>
}
