import React, { useRef, useEffect, useContext } from 'react'
import useGetPrice from '../hooks/useGetPrice'
import ESW_CLASSNAMES from '../esw/classnames'
import AppContext from '../context/app'

export default function Price(props) {
    const {
        item,
        product,
        quantity = 1,
        style = {},
        className = '',
        eswPriceClassName = '',
    } = props
    
    const { country } = useContext(AppContext)
    const { price, baseprice } = useGetPrice(product)
    const discount = item?.discount_applied ? parseFloat(item?.discount_applied) : 0
    
    let value = (price?.promotion_price ?? price?.price) * quantity
    if (value && discount) value -= discount
    
    let basevalue = (baseprice?.promotion_price ?? baseprice?.price) * quantity
    if (basevalue && discount) basevalue -= discount
    
    const price_display = price ? `${price.currency_symbol}${value.toString().includes('.') ? value.toFixed(2) : value}` : ''
    const baseprice_display = baseprice ? `${baseprice.currency_symbol}${basevalue.toFixed(2)}` : ''
    
    const elRef = useRef(null)
    useEffect(() => {
        if(!elRef || !elRef?.current) return
        elRef.current.innerText = price_display
    }, [elRef, price, item])

    return (
        <>
            <span
                ref={elRef}
                style={style}
                className={`${className} ${eswPriceClassName}`}
                data-bp-lti={baseprice_display}
            ></span>

            {item && <span
                className={`${ESW_CLASSNAMES.CARTITEM_SUBTOTAL} visually-hidden`}
                data-bp-lti={`${baseprice?.currency_symbol}${basevalue * quantity}`}
            >
                {(price && (country?.node?.currency_iso_code === price?.currency)) && `${price?.currency_symbol}${value * quantity}`}
            </span>}
        </>
    )
}
