import React, { useRef, useEffect } from 'react'
import useGetPrice from '../hooks/useGetPrice'

export default function PriceStriked(props) {
    const {
        item,
        product,
        quantity = 1,
        style = {},
        className = '',
        eswPriceStrikedClassname = '',
    } = props

    const { price, baseprice } = useGetPrice(product)
    const price_display = price ? `${price.currency_symbol}${price.price * quantity}` : ''
    const baseprice_display = baseprice ? `${baseprice.currency_symbol}${baseprice.price}` : ''
    
    const elRef = useRef(null)
    useEffect(() => {
        if(!elRef || !elRef?.current) return
        elRef.current.innerText = price_display
    }, [elRef, price, item])
    
    if (
        (price?.promotion_price) ||
        (baseprice?.promotion_price) ||
        parseFloat(item?.discount_applied)
    ) return (
        <s
            ref={elRef}
            style={style}
            className={`${className}${className ? ' ' : ''}${eswPriceStrikedClassname} text-secondary fw-semibold`}
            data-bp-lti={baseprice_display}
        ></s>
    )
}
