import { useContext, useEffect, useState } from 'react'
import AppContext from '../context/app'
import { BASE_COUNTRY_ISO } from '../../gatsby-node/constants'

export default function useGetPrice(product) {
    const { country, prices, promotions } = useContext(AppContext)
    const [price, setPrice] = useState(null)
    const [baseprice, setBaseprice] = useState(null)
    
    useEffect(() => {
        if (!product || !country || !prices || !promotions) return
        setBaseprice(prices[product?.productId][BASE_COUNTRY_ISO])
        
        const countryPrice = prices[product?.productId][country?.node?.country_iso_code]
        if(!countryPrice) return
        
        setPrice({ ...countryPrice })
    }, [product, country, prices, promotions])
    
    return { price, baseprice }
}
